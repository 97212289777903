@import 'styles/_breakpoints.scss';

.loader {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  place-items: center;
  z-index: 1500;
  background-color: black;
  position: absolute;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    width: 246px;
  }
}

.loading {
  font-size: 2.25rem;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  // -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@media (max-width: $sm) {
  .loader {
    &__image {
      width: 179px;
      height: auto;
      margin: auto;
    }
    // padding: 0 50px;
    &__text {
      width: 245px;
      margin: auto;
    }
  }
}
