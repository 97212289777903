@import 'styles/typography';
@import 'styles/breakpoints';

.hide {
  display: none;
}

.underline {
  text-decoration: underline;
}

.container {
  gap: unset;
}

.modal {
  justify-items: unset;
  text-align: unset;
  padding-inline: 16px;
  margin-block-end: 24px;
}

.title {
  height: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mint {
    margin: 0;
  }
}

.select {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

.card {
  background-color: var(--ink-base);
  padding-inline: 24px;
  padding-block: 32px;
  border-radius: 16px;
  position: relative;

  &__popular {
    border: 1px solid var(--teal-lightest);
  }

  &__chip {
    background-color: var(--teal-lightest);
    text-align: center;
    width: fit-content;
    white-space: nowrap;
    margin: auto;
    padding-block: 4px;
    padding-inline: 12px;
    border-radius: 32px;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  &__header {
    display: flex;
    gap: 16px;
  }

  &__price {
    height: 32px;
  }

  &__countdown {
    text-align: center;
  }

  &__benefitsButton {
    display: none;
  }
}

.benefits {
  margin-inline-start: 16px;
  @include body-small;

  > li {
    padding-block-end: 5px;
  }
}

.details {
  &__backButton {
    position: absolute;
    left: 0;
  }

  &__grid {
    padding-block: 24px;
    border-block: solid 1px var(--ink-lightest);
    display: grid;
    grid-template-columns: auto 2fr 3fr;
    grid-template-rows: auto;
    gap: 16px;
  }

  &__image {
    grid-row: 1/ 6;
  }

  &__divider {
    grid-column: 2/ 4;
    height: 1px;
    background-color: var(--ink-lightest);
  }

  > ul {
    color: var(--sky-base);
  }

  &__total {
    display: flex;
    justify-content: space-between;
  }

  &__price {
    text-align: right;

    .help {
      --button-icon: white;
      margin-inline-start: 8px;
      vertical-align: middle;
    }
  }

  &__row {
    display: flex;
    gap: 20px;
    padding-block-start: 32px;
    border-block-start: solid 1px var(--ink-lightest);
  }
}

.gas {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__actions {
    display: flex;
    gap: 20px;
  }
}

.asto {
  position: absolute;
  left: 20px;
}

.numberInput {
  background-color: var(--ink-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 24px;
  padding-block: 12px;
  border-radius: 8px;

  svg {
    color: var(--sky-base);
  }
}

.minting {
  position: relative;
  text-align: center;

  display: grid;
  gap: 64px;
}

.mintMessage {
  font-size: 1.25rem;
}

.success-actions {
  width: 100%;
  max-width: 300px;
  margin: auto;
  display: grid;
  gap: 16px;
}

.payment-actions {
  display: flex;
  gap: 16px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

@media (max-width: $sm) {
  .modal {
    padding-inline: 0;
  }

  .title {
    margin-block-end: 0px;
  }

  .select {
    &__content {
      grid-template-columns: 1fr;
    }
  }

  .details {
    &__backButton {
      padding-inline-start: 0px;
    }

    &__grid {
      grid-template-columns: auto 1fr;
    }

    &__image {
      grid-row: 1/ 10;
      width: 80px;
      height: 120px;

      > img {
        height: 100%;
        width: 100%;
      }
    }

    &__divider {
      grid-column: 2/ 3;
    }

    &__row {
      flex-direction: column;
      gap: 8px;
    }
  }

  .help {
    display: none;
  }

  .card {
    &__benefitsTitle {
      display: none;
    }

    &__benefitsButton {
      display: block;
      color: var(--sky-dark);
      @include body-small;
      text-transform: capitalize;
    }
  }

  .benefits {
    transform: translateY(-100%);
    z-index: -1;
    position: absolute;

    &__open {
      transform: translateY(0);
      z-index: 1;
      position: relative;
    }
  }
}

.error {
  color: var(--red-light)
}