@import 'styles/breakpoints.scss';

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 200ms ease-out;
  position: relative;
  // vertical-align: text-bottom;

  .text {
    text-transform: uppercase;
    text-align: center;
    opacity: 0;
    transition: all 500ms ease;
    position: absolute;
    bottom: -40px;
    z-index: 5;
    width: fit-content;

    &__title {
      bottom: -56px;
    }
  }

  & > a {
    display: inline-grid;
    width: 42px;
    height: 42px;

    .circle,
    span {
      grid-area: 1/2;
    }
  }

  .circle {
    width: 41px;
    height: 41px;
    z-index: 1;
    opacity: 0;
    transform: rotate(180deg);
    transition: transform 400ms ease-in-out, opacity 200ms ease;

    & > path {
      fill: var(--sky-white);
      transition: fill 50ms ease;
    }
  }

  &--disabled {
    opacity: 0.5;
  }

  &--hoverLink,
  &:hover {
    transform: translateY(-10px);
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.3));

    .text {
      opacity: 1;
    }

    & > a {
      .circle {
        opacity: 1;
        transform: rotate(360deg);
      }
    }
  }
}

@media (max-width: $md) {
  .link {
    .text {
      font-size: 10px;
      visibility: visible;
      opacity: 1;

      &__title {
        bottom: -51px;
      }
    }

    .circle {
      visibility: visible;
    }
  }
}

// remove hover animations on tablet/mobile (touchscreen)
@media (max-width: $tablet) {
  .link {
    .circle {
      opacity: 1;
      transform: none;
    }

    &:hover {
      transform: none;
      filter: none;

      & > a {
        .circle {
          transform: none;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .link {
    .link {
      height: 2rem; // align links once the text wraps
      bottom: -55px;
    }
  }
}
