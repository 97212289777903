@import 'styles/breakpoints';

.seekerInfoBox {
  position: absolute;
  right: 100px;
  bottom: 180px;
  z-index: 101;
  display: flex;
  gap: 8px;
  background-color: var(--ink-dark);
  box-shadow: 0px 0px 20px #2b3449;
  padding: 8px;
  min-height: min-content;
  max-height: 360px;
  border-radius: var(--border-radius-xs);
  overflow-y: scroll;
  scrollbar-color: var(--ink-lighter) var(--ink-light);
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  &__left {
    right: unset;
    left: 130px;
    bottom: 240px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--ink-light);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ink-lighter);
    border-radius: 20px;
  }

  & div {
    > p {
      max-width: 320px;
      background-color: var(--ink-medium-light);
      padding: 8px;
      border-radius: var(--border-radius-xs);
      margin-bottom: 8px;
    }
  }

  & div > button {
    width: fit-content;
    background-color: transparent;
    border: none;
    justify-self: start;
    --button-icon: white;

    &:hover {
      & > svg {
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
      }
    }
  }

  &__open {
    opacity: 1;
    visibility: visible;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.seekerButton {
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 6;
  width: fit-content;
  background-color: transparent;
  border: none;
  transition: filter 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 0.75rem var(--teal-light));
  }

  &__left {
    right: unset;
    left: 60px;
    bottom: 110px;
  }
}

.overlay {
  background: #000000;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  display: none;
  transition: opacity 0.5s ease-in-out;

  &__show {
    display: block;
    opacity: 0.4;
  }
}

@media (max-width: $tablet) {
  .seekerInfoBox {
    bottom: -20px;
    width: 100%;
    right: unset;
    transform: translateY(100%);
    justify-content: space-around;

    &__left {
      left: unset;
    }

    & div {
      > p:last-of-type {
        margin-bottom: 50px;
      }
    }

    &__open {
      transform: translateY(0);
    }
  }
  .seekerButton {
    position: fixed;
    bottom: unset;
    top: calc(var(--navbar-height) + 21px);
    right: 10px;
    left: unset;
  }
}

@media (max-width: $sm) {
  .overlay__show {
    display: none;
  }
}
