@import 'styles/breakpoints';

.foyer {
  height: 100%;
  width: 100vw;
  position: fixed;
  --side-margin: 6vw;
  --bottom-margin: 64px;

  .foyerNav {
    position: fixed;
  }

  .border {
    height: calc(100% - var(--navbar-height) - var(--bottom-margin));
    width: calc(100vw - var(--side-margin));
    position: relative;
    top: var(--navbar-height);
    margin: auto;
    border-radius: var(--border-radius-regular);
    overflow: hidden;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    display: block;
  }
}

.linkContainer {
  position: absolute;
  bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 62px;
}

.boardContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.boardBackground {
  z-index: -1;
  object-fit: cover;
}

.hoverBox {
  position: absolute;
  cursor: pointer;
  height: 12vh;
  width: 12vw;
  top: 50vh;
  left: 10vw;
  z-index: 1;

  &__hovered ~ span {
    filter: drop-shadow(0 0 0.75rem var(--teal-light));
  }
}

.observatoryHoverBox {
  position: absolute;
  cursor: pointer;
  height: 40vh;
  width: 12vw;
  top: 10vh;
  left: 40vw;
  z-index: 1;

  &__hovered ~ span {
    filter: drop-shadow(0 0 0.75rem var(--teal-light));
  }
}

.bookshelfHoverBox {
  --right: 520px;
  position: absolute;
  cursor: pointer;
  height: 25vh;
  width: 10vw;
  top: 45vh;
  right: calc(50% - var(--right));
  z-index: 1;

  &__hovered ~ span {
    filter: drop-shadow(0 0 0.75rem var(--teal-light));
  }
}

// .bookshelfHoverBox,
// .observatoryHoverBox,
// .hoverBox {
//   border: 2px solid red;
// }

.bookshelfHoverBox ~ span,
.observatoryHoverBox ~ span,
.hoverBox ~ span {
  transition: filter 200ms ease-in;
}

.bookshelfHoverBox:hover ~ span,
.observatoryHoverBox:hover ~ span,
.hoverBox:hover ~ span {
  filter: drop-shadow(0 0 0.75rem var(--teal-light));
}

@media (max-width: $tablet) {
  .linkContainer {
    bottom: 60px;
  }
}

@media (max-width: $sm) {
  .foyer {
    --bottom-margin: 32px;
  }
}

// Because background video uses object-fit: cover, some screen width will clip off the video to maintain aspect ratio, making the viewport width different from the video width
// Probably there's a way to use js to detect the current width of video element and use that to position the bulletin board
// But for now, this hacky css will do

@media (min-width: 1100px) {
  .hoverBox {
    left: 5vw;
  }
}

@media (min-width: 1200px) {
  .hoverBox {
    left: 7vw;
  }
}

@media (min-width: 1300px) {
  .hoverBox {
    left: 10vw;
  }
}

@media (min-width: 1400px) {
  .hoverBox {
    left: 15vw;
  }

  .bookshelfHoverBox {
    --right: 400px;
  }
}

@media (min-width: 1500px) {
  .hoverBox {
    left: 16vw;
    top: 53vh;
    width: 10vw;
  }

  .bookshelfHoverBox {
    --right: 600px;
    width: 9vw;
    top: 48vh;
  }

  .observatoryHoverBox {
    top: 5vh;
    left: 41vw;
    height: 50vh;
  }
}

@media (min-width: 1900px) {
  .bookshelfHoverBox {
    --right: 550px;
  }

  .hoverBox {
    left: 20vw;
  }
  .bookshelfHoverBox {
    width: 8vw;
  }
}

@media (min-width: 2200px) {
  .bookshelfHoverBox {
    --right: 650px;
    height: 30vh;
  }
  .hoverBox {
    top: 55vh;
    height: 15vh;
  }
}

@media (min-width: 2400px) {
  .bookshelfHoverBox {
    --right: 710px;
  }
}

// any screen that has a portrait, square, or slightly wider square shape
@media (max-aspect-ratio: 7/6) {
  .hoverBox {
    left: 0;
  }
}
